<template>
  <div
    class="container-fluid px-16 bg-white border-b"
    style="position: sticky !important; top: 0; z-index: 999"
  >
    <el-menu
      class="d-flex border-0 align-items-center px-3 custom-header"
      mode="horizontal"
      :ellipsis="false"
      :router="true"
    >
      <img src="../../assets/img/Logos/LogoFinal2.png" width="180" alt="" />
      <!--      <el-menu-item index="0">-->
      <!--        &lt;!&ndash; <span class="text-xl md:text-4xl font-semibold transition-all">Medatiq</span> &ndash;&gt;-->
      <!--        <img src="../../assets/img/logo.png" width="80" alt="">-->
      <!--      </el-menu-item>-->
      <!-- <el-menu-item class="d-none d-lg-flex h-50 rounded-1" index="1">
        <span class="text-base ">Trading</span></el-menu-item>
      <el-menu-item class="d-none d-lg-flex h-50 rounded-1" index="2">
        <span class="text-base ">Market</span></el-menu-item>
      <el-menu-item class="d-none d-lg-flex h-50 rounded-1" index="3">
        <span class="text-base ">Platform</span></el-menu-item>
      <el-menu-item class="d-none d-lg-flex h-50 rounded-1" index="4">
        <span class="text-base ">Tools</span></el-menu-item>
      <el-menu-item class="d-none d-lg-flex h-50 rounded-1" index="5">
        <span class="text-base ">Company</span></el-menu-item>
      <el-menu-item class="d-none d-lg-flex h-50 rounded-1" index="6">
        <span class="text-base ">Partners</span></el-menu-item> -->

      <div class="flex-grow" />
      <n-space class="d-none d-lg-flex">
        <a href="http://my.medatiqmarket.com?register=true" target="_blank">
        <n-button :color="colors.primary" class="text-black" size="large">
         
            Signup</n-button
        ></a>
        <a href="http://my.medatiqmarket.com/" target="_blank">
        <n-button
          :color="colors['logo-secondary']"
          class="text-white"
          size="large"
        >
          
            Sign In</n-button
        ></a>
      </n-space>
      <n-button
        secondary
        strong
        class="d-flex justify-content-center align-items-center d-lg-none"
        @click="showDrawer = !showDrawer"
      >
        <i class="mgc_menu_line text-xl"></i>
      </n-button>
    </el-menu>

    <n-drawer
      :z-index="999"
      v-model:show="showDrawer"
      :width="300"
      placement="left"
    >
      <n-drawer-content>
        <template #header>
          <!-- <span class="text-xl md:text-4xl font-semibold transition-all">Medatiq</span> -->
          <img
            src="../../assets/img/Logos/LogoFinal2.png"
            alt="Logo"
            width="160"
          />
        </template>
        <template #default>
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo w-100 custom-sidebar border-0 d-flex flex-column"
          >
            <!-- <el-menu-item class="vertical-menu-item rounded-1" index="1">
              <span class="text-base">Trading</span></el-menu-item>
            <el-menu-item class="vertical-menu-item rounded-1" index="2">
              <span class="text-base">Market</span></el-menu-item>
            <el-menu-item class="vertical-menu-item rounded-1" index="3">
              <span class="text-base">Platform</span></el-menu-item>
            <el-menu-item class="vertical-menu-item rounded-1" index="4">
              <span class="text-base">Tools</span></el-menu-item>
            <el-menu-item class="vertical-menu-item rounded-1" index="5">
              <span class="text-base">Company</span></el-menu-item>
            <el-menu-item class="vertical-menu-item rounded-1" index="6">
              <span class="text-base">Partners</span></el-menu-item> -->
            <div class="flex-grow" />
            <n-button
              :color="colors.primary"
              class="text-black w-full my-1"
              size="large"
            >
              <a href="https://my.medatiq.com?register=true">
                Register
              </a></n-button
            >
            <n-button
              :color="colors['logo-secondary']"
              class="text-white w-full my-1"
              size="large"
            >
              <a href="https://my.medatiq.com"> Sign In </a>
            </n-button>
          </el-menu>
        </template>
      </n-drawer-content>
    </n-drawer>
  </div>
</template>
<script>
import { NButton, NDrawer, NDrawerContent, NSpace } from "naive-ui";
import colors from "@/Utility/colors";

export default {
  name: "HeaderComp",
  components: { NSpace, NButton, NDrawerContent, NDrawer },
  data() {
    return {
      showDrawer: false,
      colors: colors,
    };
  },
  computed: {},
  mounted() {
    // Initially check screen size
    this.checkScreenSize();
    // Add event listener to window resize event
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeUnmount() {
    // Remove event listener when component is destroyed
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      // Check if screen width exceeds 768px
      if (window.innerWidth > 768 && this.showDrawer) {
        this.showDrawer = false; // Close drawer if open
      }
    },
  },
};
</script>

<style scoped></style>

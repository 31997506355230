import {createRouter, createWebHistory} from "vue-router";
import LoginPage from "@/components/auth/login.vue";
import HomePage from "@/components/views/home.vue"

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: HomePage,
    }, {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;

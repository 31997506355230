export default {
    "secodary": '#6c859529',
    "logo-secondary": '#545454',
    "primary": "#EFA52C",
    // 'primary': "#fbc02d",
    "transparent": "transparent",
    "black": "#000000",
    "white": "#ffffff",
    "red": "#f44336",
    "red50": "#ffebee",
    "red100": "#ffcdd2",
    "red200": "#ef9a9a",
    "red300": "#e57373",
    "red400": "#ef5350",
    "red500": "#f44336",
    "red600": "#e53935",
    "red700": "#d32f2f",
    "red800": "#c62828",
    "red900": "#b71c1c",
    "red100accent": "#ff8a80",
    "red200accent": "#ff5252",
    "red400accent": "#ff1744",
    "red700accent": "#d50000",
    "pink50": "#fce4ec",
    "pink100": "#f8bbd0",
    "pink200": "#f48fb1",
    "pink300": "#f06292",
    "pink400": "#ec407a",
    "pink500": "#e91e63",
    "pink600": "#d81b60",
    "pink700": "#c2185b",
    "pink800": "#ad1457",
    "pink900": "#880e4f",
    "pink100accent": "#ff80ab",
    "pink200accent": "#ff4081",
    "pink400accent": "#f50057",
    "pink700accent": "#c51162",
    "purple": "#9c27b0",
    "purple50": "#f3e5f5",
    "purple100": "#e1bee7",
    "purple200": "#ce93d8",
    "purple300": "#ba68c8",
    "purple400": "#ab47bc",
    "purple500": "#9c27b0",
    "purple600": "#8e24aa",
    "purple700": "#7b1fa2",
    "purple800": "#6a1b9a",
    "purple900": "#4a148c",
    "purple100accent": "#ea80fc",
    "purple200accent": "#e040fb",
    "purple400accent": "#d500f9",
    "purple700accent": "#aa00ff",
    "deeppurple": "#673ab7",
    "deeppurple50": "#ede7f6",
    "deeppurple100": "#d1c4e9",
    "deeppurple200": "#b39ddb",
    "deeppurple300": "#9575cd",
    "deeppurple400": "#7e57c2",
    "deeppurple500": "#673ab7",
    "deeppurple600": "#5e35b1",
    "deeppurple700": "#512da8",
    "deeppurple800": "#4527a0",
    "deeppurple900": "#311b92",
    "deeppurple100accent": "#b388ff",
    "deeppurple200accent": "#7c4dff",
    "deeppurple400accent": "#651fff",
    "deeppurple700accent": "#6200ea",
    "indigo": "#3f51b5",
    "indigo50": "#e8eaf6",
    "indigo100": "#c5cae9",
    "indigo200": "#9fa8da",
    "indigo300": "#7986cb",
    "indigo400": "#5c6bc0",
    "indigo500": "#3f51b5",
    "indigo600": "#3949ab",
    "indigo700": "#303f9f",
    "indigo800": "#283593",
    "indigo900": "#1a237e",
    "indigo100accent": "#8c9eff",
    "indigo200accent": "#536dfe",
    "indigo400accent": "#3d5afe",
    "indigo700accent": "#304ffe",
    "blue": "#2196f3",
    "blue50": "#e3f2fd",
    "blue100": "#bbdefb",
    "blue200": "#90caf9",
    "blue300": "#64b5f6",
    "blue400": "#42a5f5",
    "blue500": "#2196f3",
    "blue600": "#1e88e5",
    "blue700": "#1976d2",
    "blue800": "#1565c0",
    "blue900": "#0d47a1",
    "blue100accent": "#82b1ff",
    "blue200accent": "#448aff",
    "blue400accent": "#2979ff",
    "blue700accent": "#2962ff",
    "lightblue": "#03a9f4",
    "lightblue50": "#e1f5fe",
    "lightblue100": "#b3e5fc",
    "lightblue200": "#81d4fa",
    "lightblue300": "#4fc3f7",
    "lightblue400": "#29b6f6",
    "lightblue500": "#03a9f4",
    "lightblue600": "#039be5",
    "lightblue700": "#0288d1",
    "lightblue800": "#0277bd",
    "lightblue900": "#01579b",
    "lightblue100accent": "#80d8ff",
    "lightblue200accent": "#40c4ff",
    "lightblue400accent": "#00b0ff",
    "lightblue700accent": "#0091ea",
    "cyan": "#00bcd4",
    "cyan50": "#e0f7fa",
    "cyan100": "#b2ebf2",
    "cyan200": "#80deea",
    "cyan300": "#4dd0e1",
    "cyan400": "#26c6da",
    "cyan500": "#00bcd4",
    "cyan600": "#00acc1",
    "cyan700": "#0097a7",
    "cyan800": "#00838f",
    "cyan900": "#006064",
    "cyan100accent": "#84ffff",
    "cyan200accent": "#18ffff",
    "cyan400accent": "#00e5ff",
    "cyan700accent": "#00b8d4",
    "teal": "#009688",
    "teal50": "#e0f2f1",
    "teal100": "#b2dfdb",
    "teal200": "#80cbc4",
    "teal300": "#4db6ac",
    "teal400": "#26a69a",
    "teal500": "#009688",
    "teal600": "#00897b",
    "teal700": "#00796b",
    "teal800": "#00695c",
    "teal900": "#004d40",
    "teal100accent": "#a7ffeb",
    "teal200accent": "#64ffda",
    "teal400accent": "#1de9b6",
    "teal700accent": "#00bfa5",
    "green": "#4caf50",
    "green50": "#e8f5e9",
    "green100": "#c8e6c9",
    "green200": "#a5d6a7",
    "green300": "#81c784",
    "green400": "#66bb6a",
    "green500": "#4caf50",
    "green600": "#43a047",
    "green700": "#388e3c",
    "green800": "#2e7d32",
    "green900": "#1b5e20",
    "green100accent": "#b9f6ca",
    "green200accent": "#69f0ae",
    "green400accent": "#00e676",
    "green700accent": "#00c853",
    "lightgreen": "#8bc34a",
    "lightgreen50": "#f1f8e9",
    "lightgreen100": "#dcedc8",
    "lightgreen200": "#c5e1a5",
    "lightgreen300": "#aed581",
    "lightgreen400": "#9ccc65",
    "lightgreen500": "#8bc34a",
    "lightgreen600": "#7cb342",
    "lightgreen700": "#689f38",
    "lightgreen800": "#558b2f",
    "lightgreen900": "#33691e",
    "lightgreen100accent": "#ccff90",
    "lightgreen200accent": "#b2ff59",
    "lightgreen400accent": "#76ff03",
    "lightgreen700accent": "#64dd17",
    "lime": "#cddc39",
    "lime50": "#f9fbe7",
    "lime100": "#f0f4c3",
    "lime200": "#e6ee9c",
    "lime300": "#dce775",
    "lime400": "#d4e157",
    "lime500": "#cddc39",
    "lime600": "#c0ca33",
    "lime700": "#afb42b",
    "lime800": "#9e9d24",
    "lime900": "#827717",
    "lime100accent": "#f4ff81",
    "lime200accent": "#eeff41",
    "lime400accent": "#c6ff00",
    "lime700accent": "#aeea00",
    "yellow": "#ffeb3b",
    "yellow50": "#fffde7",
    "yellow100": "#fff9c4",
    "yellow200": "#fff59d",
    "yellow300": "#fff176",
    "yellow400": "#ffee58",
    "yellow500": "#ffeb3b",
    "yellow600": "#fdd835",
    "yellow700": "#fbc02d",
    "yellow800": "#f9a825",
    "yellow900": "#f57f17",
    "yellow100accent": "#ffff8d",
    "yellow200accent": "#ffff00",
    "yellow400accent": "#ffea00",
    "yellow700accent": "#ffd600",
    "amber": "#ffc107",
    "amber50": "#fff8e1",
    "amber100": "#ffecb3",
    "amber200": "#ffe082",
    "amber300": "#ffd54f",
    "amber400": "#ffca28",
    "amber500": "#ffc107",
    "amber600": "#ffb300",
    "amber700": "#ffa000",
    "amber800": "#ff8f00",
    "amber900": "#ff6f00",
    "amber100accent": "#ffe57f",
    "amber200accent": "#ffd740",
    "amber400accent": "#ffc400",
    "amber700accent": "#ffab00",
    "orange": "#ff9800",
    "orange50": "#fff3e0",
    "orange100": "#ffe0b2",
    "orange200": "#ffcc80",
    "orange300": "#ffb74d",
    "orange400": "#ffa726",
    "orange500": "#ff9800",
    "orange600": "#fb8c00",
    "orange700": "#f57c00",
    "orange800": "#ef6c00",
    "orange900": "#e65100",
    "orange100accent": "#ffd180",
    "orange200accent": "#ffab40",
    "orange400accent": "#ff9100",
    "orange700accent": "#ff6d00",
    "deeporange": "#ff5722",
    "deeporange50": "#fbe9e7",
    "deeporange100": "#ffccbc",
    "deeporange200": "#ffab91",
    "deeporange300": "#ff8a65",
    "deeporange400": "#ff7043",
    "deeporange500": "#ff5722",
    "deeporange600": "#f4511e",
    "deeporange700": "#e64a19",
    "deeporange800": "#d84315",
    "deeporange900": "#bf360c",
    "deeporange100accent": "#ff9e80",
    "deeporange200accent": "#ff6e40",
    "deeporange400accent": "#ff3d00",
    "deeporange700accent": "#dd2c00",
    "brown": "#795548",
    "brown50": "#efebe9",
    "brown100": "#d7ccc8",
    "brown200": "#bcaaa4",
    "brown300": "#a1887f",
    "brown400": "#8d6e63",
    "brown500": "#795548",
    "brown600": "#6d4c41",
    "brown700": "#5d4037",
    "brown800": "#4e342e",
    "brown900": "#3e2723",
    "grey": "#9e9e9e",
    "grey50": "#fafafa",
    "grey100": "#f5f5f5",
    "grey200": "#eeeeee",
    "grey300": "#e0e0e0",
    "grey400": "#bdbdbd",
    "grey500": "#9e9e9e",
    "grey600": "#757575",
    "grey700": "#616161",
    "grey800": "#424242",
    "grey900": "#212121",
    "bluegrey": "#607d8b",
    "bluegrey50": "#eceff1",
    "bluegrey100": "#cfd8dc",
    "bluegrey200": "#b0bec5",
    "bluegrey300": "#90a4ae",
    "bluegrey400": "#78909c",
    "bluegrey500": "#607d8b",
    "bluegrey600": "#546e7a",
    "bluegrey700": "#455a64",
    "bluegrey800": "#37474f",
    "bluegrey900": "#263238",
    "redcontrast": "white",
    "red50contrast": "black",
    "red100contrast": "black",
    "red200contrast": "black",
    "red300contrast": "black",
    "red400contrast": "black",
    "red500contrast": "white",
    "red600contrast": "white",
    "red700contrast": "white",
    "red800contrast": "white",
    "red900contrast": "white",
    "red100accentcontrast": "black",
    "red200accentcontrast": "white",
    "red400accentcontrast": "white",
    "red700accentcontrast": "white",
    "pinkcontrast": "white",
    "pink50contrast": "black",
    "pink100contrast": "black",
    "pink200contrast": "black",
    "pink300contrast": "black",
    "pink400contrast": "black",
    "pink500contrast": "white",
    "pink600contrast": "white",
    "pink700contrast": "white",
    "pink800contrast": "white",
    "pink900contrast": "white",
    "pink100accentcontrast": "black",
    "pink200accentcontrast": "white",
    "pink400accentcontrast": "white",
    "pink700accentcontrast": "white",
    "purplecontrast": "white",
    "purple50contrast": "black",
    "purple100contrast": "black",
    "purple200contrast": "black",
    "purple300contrast": "white",
    "purple400contrast": "white",
    "purple500contrast": "white",
    "purple600contrast": "white",
    "purple700contrast": "white",
    "purple800contrast": "white",
    "purple900contrast": "white",
    "purple100accentcontrast": "black",
    "purple200accentcontrast": "white",
    "purple400accentcontrast": "white",
    "purple700accentcontrast": "white",
    "deeppurplecontrast": "white",
    "deeppurple50contrast": "black",
    "deeppurple100contrast": "black",
    "deeppurple200contrast": "black",
    "deeppurple300contrast": "white",
    "deeppurple400contrast": "white",
    "deeppurple500contrast": "white",
    "deeppurple600contrast": "white",
    "deeppurple700contrast": "white",
    "deeppurple800contrast": "white",
    "deeppurple900contrast": "white",
    "deeppurple100accentcontrast": "black",
    "deeppurple200accentcontrast": "white",
    "deeppurple400accentcontrast": "white",
    "deeppurple700accentcontrast": "white",
    "indigocontrast": "white",
    "indigo50contrast": "black",
    "indigo100contrast": "black",
    "indigo200contrast": "black",
    "indigo300contrast": "white",
    "indigo400contrast": "white",
    "indigo500contrast": "white",
    "indigo600contrast": "white",
    "indigo700contrast": "white",
    "indigo800contrast": "white",
    "indigo900contrast": "white",
    "indigo100accentcontrast": "black",
    "indigo200accentcontrast": "white",
    "indigo400accentcontrast": "white",
    "indigo700accentcontrast": "white",
    "bluecontrast": "white",
    "blue50contrast": "black",
    "blue100contrast": "black",
    "blue200contrast": "black",
    "blue300contrast": "black",
    "blue400contrast": "black",
    "blue500contrast": "white",
    "blue600contrast": "white",
    "blue700contrast": "white",
    "blue800contrast": "white",
    "blue900contrast": "white",
    "blue100accentcontrast": "black",
    "blue200accentcontrast": "white",
    "blue400accentcontrast": "white",
    "blue700accentcontrast": "white",
    "lightbluecontrast": "white",
    "lightblue50contrast": "black",
    "lightblue100contrast": "black",
    "lightblue200contrast": "black",
    "lightblue300contrast": "black",
    "lightblue400contrast": "black",
    "lightblue500contrast": "white",
    "lightblue600contrast": "white",
    "lightblue700contrast": "white",
    "lightblue800contrast": "white",
    "lightblue900contrast": "white",
    "lightblue100accentcontrast": "black",
    "lightblue200accentcontrast": "black",
    "lightblue400accentcontrast": "black",
    "lightblue700accentcontrast": "white",
    "cyancontrast": "white",
    "cyan50contrast": "black",
    "cyan100contrast": "black",
    "cyan200contrast": "black",
    "cyan300contrast": "black",
    "cyan400contrast": "black",
    "cyan500contrast": "white",
    "cyan600contrast": "white",
    "cyan700contrast": "white",
    "cyan800contrast": "white",
    "cyan900contrast": "white",
    "cyan100accentcontrast": "black",
    "cyan200accentcontrast": "black",
    "cyan400accentcontrast": "black",
    "cyan700accentcontrast": "black",
    "tealcontrast": "white",
    "teal50contrast": "black",
    "teal100contrast": "black",
    "teal200contrast": "black",
    "teal300contrast": "black",
    "teal400contrast": "black",
    "teal500contrast": "white",
    "teal600contrast": "white",
    "teal700contrast": "white",
    "teal800contrast": "white",
    "teal900contrast": "white",
    "teal100accentcontrast": "black",
    "teal200accentcontrast": "black",
    "teal400accentcontrast": "black",
    "teal700accentcontrast": "black",
    "greencontrast": "white",
    "green50contrast": "black",
    "green100contrast": "black",
    "green200contrast": "black",
    "green300contrast": "black",
    "green400contrast": "black",
    "green500contrast": "white",
    "green600contrast": "white",
    "green700contrast": "white",
    "green800contrast": "white",
    "green900contrast": "white",
    "green100accentcontrast": "black",
    "green200accentcontrast": "black",
    "green400accentcontrast": "black",
    "green700accentcontrast": "black",
    "lightgreencontrast": "black",
    "lightgreen50contrast": "black",
    "lightgreen100contrast": "black",
    "lightgreen200contrast": "black",
    "lightgreen300contrast": "black",
    "lightgreen400contrast": "black",
    "lightgreen500contrast": "black",
    "lightgreen600contrast": "black",
    "lightgreen700contrast": "black",
    "lightgreen800contrast": "white",
    "lightgreen900contrast": "white",
    "lightgreen100accentcontrast": "black",
    "lightgreen200accentcontrast": "black",
    "lightgreen400accentcontrast": "black",
    "lightgreen700accentcontrast": "black",
    "limecontrast": "black",
    "lime50contrast": "black",
    "lime100contrast": "black",
    "lime200contrast": "black",
    "lime300contrast": "black",
    "lime400contrast": "black",
    "lime500contrast": "black",
    "lime600contrast": "black",
    "lime700contrast": "black",
    "lime800contrast": "black",
    "lime900contrast": "white",
    "lime100accentcontrast": "black",
    "lime200accentcontrast": "black",
    "lime400accentcontrast": "black",
    "lime700accentcontrast": "black",
    "yellowcontrast": "black",
    "yellow50contrast": "black",
    "yellow100contrast": "black",
    "yellow200contrast": "black",
    "yellow300contrast": "black",
    "yellow400contrast": "black",
    "yellow500contrast": "black",
    "yellow600contrast": "black",
    "yellow700contrast": "black",
    "yellow800contrast": "black",
    "yellow900contrast": "black",
    "yellow100accentcontrast": "black",
    "yellow200accentcontrast": "black",
    "yellow400accentcontrast": "black",
    "yellow700accentcontrast": "black",
    "ambercontrast": "black",
    "amber50contrast": "black",
    "amber100contrast": "black",
    "amber200contrast": "black",
    "amber300contrast": "black",
    "amber400contrast": "black",
    "amber500contrast": "black",
    "amber600contrast": "black",
    "amber700contrast": "black",
    "amber800contrast": "black",
    "amber900contrast": "black",
    "amber100accentcontrast": "black",
    "amber200accentcontrast": "black",
    "amber400accentcontrast": "black",
    "amber700accentcontrast": "black",
    "orangecontrast": "black",
    "orange50contrast": "black",
    "orange100contrast": "black",
    "orange200contrast": "black",
    "orange300contrast": "black",
    "orange400contrast": "black",
    "orange500contrast": "black",
    "orange600contrast": "black",
    "orange700contrast": "black",
    "orange800contrast": "white",
    "orange900contrast": "white",
    "orange100accentcontrast": "black",
    "orange200accentcontrast": "black",
    "orange400accentcontrast": "black",
    "orange700accentcontrast": "black",
    "deeporangecontrast": "white",
    "deeporange50contrast": "black",
    "deeporange100contrast": "black",
    "deeporange200contrast": "black",
    "deeporange300contrast": "black",
    "deeporange400contrast": "black",
    "deeporange500contrast": "white",
    "deeporange600contrast": "white",
    "deeporange700contrast": "white",
    "deeporange800contrast": "white",
    "deeporange900contrast": "white",
    "deeporange100accentcontrast": "black",
    "deeporange200accentcontrast": "black",
    "deeporange400accentcontrast": "white",
    "deeporange700accentcontrast": "white",
    "browncontrast": "white",
    "brown50contrast": "black",
    "brown100contrast": "black",
    "brown200contrast": "black",
    "brown300contrast": "white",
    "brown400contrast": "white",
    "brown500contrast": "white",
    "brown600contrast": "white",
    "brown700contrast": "white",
    "brown800contrast": "white",
    "brown900contrast": "white",
    "greycontrast": "black",
    "grey50contrast": "black",
    "grey100contrast": "black",
    "grey200contrast": "black",
    "grey300contrast": "black",
    "grey400contrast": "black",
    "grey500contrast": "black",
    "grey600contrast": "white",
    "grey700contrast": "white",
    "grey800contrast": "white",
    "grey900contrast": "white",
    "bluegreycontrast": "white",
    "bluegrey50contrast": "black",
    "bluegrey100contrast": "black",
    "bluegrey200contrast": "black",
    "bluegrey300contrast": "black",
    "bluegrey400contrast": "white",
    "bluegrey500contrast": "white",
    "bluegrey600contrast": "white",
    "bluegrey700contrast": "white",
    "bluegrey800contrast": "white",
    "bluegrey900contrast": "white",
    'pink': {
        '50': '#fdf2f8',
        '100': '#fce7f3',
        '200': '#fbcfe8',
        '300': '#f9a8d4',
        '400': '#f472b6',
        '500': '#ec4899',
        '600': '#db2777',
        '700': '#be185d',
        '800': '#9d174d',
        '900': '#831843',
        '950': '#500724',
    },
    'fuchsia': {
        '50': '#fdf4ff',
        '100': '#fae8ff',
        '200': '#f5d0fe',
        '300': '#f0abfc',
        '400': '#e879f9',
        '500': '#d946ef',
        '600': '#c026d3',
        '700': '#a21caf',
        '800': '#86198f',
        '900': '#701a75',
        '950': '#4a044e',
    },
}
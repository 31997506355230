<template>
  <div>
    <input
        :type="type"
        :name="name"
        :id="id"
        :class="inputClasses"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
        required
    />
  </div>
</template>

<script>
export default {
  name: 'PriceInput',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: 'price'
    },
    id: {
      type: String,
      default: 'price'
    },
    placeholder: {
      type: String,
      default: '0.00'
    }
  },
  computed: {
    inputClasses() {
      return 'block w-full rounded-md border-0 py-1.5 pl-3  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-blue-700 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6';
    }
  }
};
</script>

<template>
  <div>
    <HeaderComp />
    <router-view />
  </div>
</template>
<script>
import HeaderComp from "@/components/navigation/header.vue";

export default {
  components: {HeaderComp},
  data() {
    return {};
  },
};
</script>
<style>
@import "./assets/Styles/Style.css";
@import "./assets/MingIcons/MingCute.css";
@import "./assets/RemixIcons/remixicon.css";
@import "assets/bootstrap/css/bootstrap.css";
@import './assets/tailwind.css';

.my-router {
  overflow-x: hidden !important;
}

.main-r {
  border-left: 1px solid #e3e3e3;
  position: relative !important;
}
*{
  font-family: 'Poppins';
}
</style>

<template>
  <div>
    <div class="container-fluid p-0 m-0">
 
          <div
            class="bg-logo-yellow h-24 my-2 d-flex align-items-center justify-center px-3"
          >
            <marquee scrollamount="20">
              <span class="md:text-3xl transition-all font-semibold"
              >GET 10% BONUS ON YOUR FIRST DEPOSIT</span
            >
            </marquee>
          </div>
      </div>
    <div class="container-fluid py-10 bg-hero section-main">
      
      <div class="container">
        <div class="row min-h-96 py-20">
          <div class="col-12 text-center">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <span
                class="text-3xl md:text-5xl transition-all font-semibold md:font-bold"
                >LETS GROW TOGETHER
              </span>
              <span
                class="text-secondary text-base md:text-xl transition-all mt-3"
              >
                Our vision is to Built Your Trust.</span
              >
              <n-space class="my-5">
                <a
                  href="http://my.medatiqmarket.com?register=true"
                  target="_blank"
                >
                  <n-button
                    :color="colors.primary"
                    class="text-black w-32"
                    size="large"
                  >
                    Signup
                  </n-button>
                </a>
                <a href="https://my.medatiqmarket.com" target="_blank">
                  <n-button
                    :color="colors['logo-secondary']"
                    class="text-white w-32"
                    size="large"
                  >
                    Sign In</n-button
                  ></a
                >
              </n-space>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="container">
        <div class="row justify-center">
          <div class="col-12 col-md-10 border-t">
            <div class="row">
              <div class="col-12 col-lg-3 py-3 py-md-5 text-center">
                <i class="bi bi-person"></i>
                <span class="ms-2">10000 + active traders</span>
              </div>
              <div class="col-12 col-lg-3 py-3 py-md-5 text-center">
                <i class="bi bi-file-earmark-text"></i>
                <span class="ms-2">Multiple regulatory licenses</span>
              </div>
              <div class="col-12 col-lg-3 py-3 py-md-5 text-center">
                <i class="bi bi-headset"></i>
                <span class="ms-2">24/7 customer support</span>
              </div>
              <div class="col-12 col-lg-3 py-3 py-md-5 text-center">
                <i class="bi bi-file-earmark-check"></i>
                <span class="ms-2">PCI DSS certified</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-section-logo text-section">
      <div class="container-xl mx-auto py-30 pb-0">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="text-center py-20 pb-10">
            <span
              class="text-section font-bold py-20 text-2xl md:text-4xl transition-all"
              >Trade Like Experts
            </span>
          </div>
          <div class="col-8 col-md-3">
            <div
              class="flex flex-col content-center items-center text-center my-2"
            >
              <n-tag
                round
                :bordered="false"
                size="small"
                class="w-auto px-2 py-1 bg-dark text-white my-3"
                >Withdrawals
              </n-tag>
              <span class="text-xl text-section">Instant withdrawals</span>
              <span class="my-1 text-section text-sm"
                >Fast Withdrawal and Deposit .</span
              >
            </div>
            <div
              class="flex flex-col content-center items-center text-center my-2"
            >
              <n-tag
                round
                :bordered="false"
                size="small"
                class="w-auto px-2 py-1 bg-dark text-white my-3"
                >Spreads
              </n-tag>
              <span class="text-xl text-section"
                >Tight and stable spreads
              </span>
              <span class="my-1 text-section text-sm"
                >Enjoy ultra low spreads on every pair up to 0.10 pips.</span
              >
            </div>
          </div>
          <div class="col-8 col-md-6 p-5">
            <div class="flex flex-col content-center items-center text-center">
              <img
                src="../../assets/img/mobile.png"
                srcset=""
                data-main-image=""
                class="phone-img"
                data-testid="image"
                alt="Medatiq-phone-home-page.jpg"
              />
            </div>
          </div>
          <div class="col-8 col-md-3">
            <div class="flex flex-col content-center items-center text-center">
              <n-tag
                round
                :bordered="false"
                size="small"
                class="w-auto px-2 py-1 bg-dark text-white my-3"
                >Payment Method
              </n-tag>
              <span class="text-xl text-section"
                >Different Payment Methods</span
              >
              <span class="my-1 text-section text-sm"
                >USDT LOCAL BANKS Withdrawal and Deposit option Available
              </span>
            </div>
            <div class="flex flex-col content-center items-center text-center">
              <n-tag
                round
                :bordered="false"
                size="small"
                class="w-auto px-2 py-1 bg-dark text-white my-3"
                >Swaps
              </n-tag>
              <span class="text-xl text-section">No overnight fees </span>
              <span class="my-1 text-section text-sm"
                >Hold your leveraged positions for as long as you like,
                swap-free. T&C apply.
              </span>
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="text-center py-20 pb-10">
            <div class="text-center pb-10">
              <span
                class="text-section font-bold text-2xl md:text-4xl transition-all d-block"
                >Trade Like Experts
              </span>
              <span
                class="text-section text-base md:text-xl transition-all py-10"
                >Your Greatest Assets is your Earning Ability.</span
              >
            </div>
          </div>
          <div class="col-12 section-fontColor bg-transparent pb-20">
            <div class="text-center">
              <div class="d-flex justify-center">
                <a
                  href="https://my.medatiqmarket.com?register=true"
                  target="_blank"
                >
                  <n-button
                    :color="colors['logo-secondary']"
                    class="px-4 py-4"
                    size="large"
                  >
                    <span class="text-lg font-semibold">Signup</span>
                  </n-button>
                </a>
                <a href="https://my.medatiqmarket.com" target="_blank">
                  <n-button
                    :color="colors.secodary"
                    class="text-black ms-3 px-4 py-4"
                    size="large"
                  >
                    <span class="text-lg text-dark font-semibold"
                      >Try free demo</span
                    >
                  </n-button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="container-xl mx-auto pb-0">
      <div class="row align-items-center md:mt-0" style="height: 616px">
        <div class="col-12 col-md-6">
          <div>
            <img
              src="../../assets/img/computers.png"
              srcset=""
              width="616"
              height="616"
              class="rounded-2"
              alt="Medatiq-trading-apps.jpg"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 px-5 mt-4 mt-md-0">
          <div class="flex flex-col">
            <span class="text-4xl font-semibold">Seize every opportunity</span>
            <span class="text-base text-secondary"
              >Trade online anytime, anywhere. On web, mobile and desktop.</span
            >
          </div>
          <div class="flex flex-col mt-5">
            <ul class="text-secondary">
              <li class="py-2">
                <a
                  href="https://hybridsolutions.com/downloads/VertexFXTraderLite_1.7.apk"
                >
                  Vertexfx for Android >
                </a>
              </li>
              <li class="py-2">
                <a
                  href="https://www.hybridsolutions.com/downloads/smartexchange.exe"
                >
                  Vertexfx for Desktop >
                </a>
              </li>
              <li class="py-2">
                <a
                  href="https://apps.apple.com/us/app/vertexfx-trader-lite/id1555704316"
                >
                  Vertexfx for Iphone &nbsp;&nbsp;>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <el-divider border-style="dashed"></el-divider>
      <div class="row align-items-center mt-4 mt-md-0">
        <div class="col-12 col-md-6 px-5">
          <div class="flex flex-col">
            <span class="text-4xl font-semibold"
              >Your security is our priority</span
            >
            <span class="text-base text-secondary mt-3"
              >TFrom secure payments to negative balance protection, you are
              covered from every angle.</span
            >
          </div>
          <div class="flex flex-col mt-5">
            <ul class="text-secondary">
              <li class="py-2"><a href=""> Client protection 5 > </a></li>
              <li class="py-2"><a href=""> Why Medatiq > </a></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-0">
          <div>
            <img
              src="../../assets/img/lock.jpg"
              srcset=""
              width="616"
              class="rounded-2"
              alt="Medatiq-trading-apps.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-divider border-style="dashed"></el-divider>
  <div class="container-fluid py-5">
    <div class="container-xl mx-auto pb-0">
      <div class="row align-items-center">
        <span class="text-center text-4xl text-black font-semibold"
          >Stay ahead of the markets</span
        >
        <span class="text-center text-base"
          >Get the latest market news, trading analysis, and feature
          updates.</span
        >
        <span class="text-center text-sm text-secondary my-3"
          ><a href="">View all market news ></a></span
        >
        <div class="col-12 col-md-4 my-2">
          <n-card hoverable>
            <template #cover>
              <img
                alt="apple"
                style="height: 276px"
                src="../../assets/img/3.png"
              />
            </template>
            <template #header>
              <n-tag
                round
                size="small"
                class="w-auto px-2 py-1 bg-gray-200 text-gray-800 my-3"
                >Insights
              </n-tag>
            </template>
            <span class="text-small"
              >Connecting minds, creating the future.</span
            >
          </n-card>
        </div>
        <div class="col-12 col-md-4 my-2">
          <n-card hoverable>
            <template #cover>
              <img
                alt="apple"
                src="../../assets/img/4.png"
                style="height: 276px"
              />
            </template>
            <template #header>
              <n-tag
                round
                size="small"
                class="w-auto px-2 py-1 bg-gray-200 text-gray-800 my-3"
                >Insights
              </n-tag>
            </template>
            <span class="text-small">Feel Free With Us.</span>
          </n-card>
        </div>
        <div class="col-12 col-md-4 my-2">
          <n-card hoverable>
            <template #cover>
              <img
                alt="apple"
                src="../../assets/img/connect.jpg"
                style="height: 276px"
              />
            </template>
            <template #header>
              <n-tag
                round
                size="small"
                class="w-auto px-2 py-1 bg-gray-200 text-gray-800 my-3"
                >Insights
              </n-tag>
            </template>
            <span class="text-small"
              >Medatiq Search On Google any Time Anywhere</span
            >
          </n-card>
        </div>
      </div>
    </div>
  </div>
  <div style="height: 350px">
    <div class="img-sec position-relative">
      <div
        class="container-xl bg-section-logo ext-section rounded-2 position-absolute"
        style="bottom: -50px; left: 50%; transform: translateX(-50%)"
      >
        <div class="row p-5 align-items-center">
          <div class="col-12 col-md-8 text-section">
            <div class="flex flex-col text-center md:text-start">
              <span class="text-xl md:text-4xl transition-all"
                >Trade Assets From Global Markets
              </span>
              <span class="text-sm md:text-base transition-all text-section"
                >Your Greatest Assets is your Earning Ability.</span
              >
            </div>
          </div>
          <div class="col-12 col-md-4 text-white mt-3 md:mt-0">
            <div class="flex justify-center">
              <a
                href="https://my.medatiqmarket.com?register=true"
                target="_blank"
              >
                <n-button
                  :color="colors['logo-secondary']"
                  class="text-white px-4 py-4"
                  size="large"
                >
                  <span class="text-lg font-semibold">Signup</span>
                </n-button></a
              >
              <a href="https://my.medatiqmarket.com" target="_blank">
                <n-button
                  :color="colors.secodary"
                  class="text-black ms-3 px-4 py-4"
                  size="large"
                >
                  <span class="text-lg text-white font-semibold">
                    Try free demo</span
                  >
                </n-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5 bg-section py-5 text-white">
    <div class="container-lg">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 flex justify-center md:justify-start">
          <span class="text-xs">All rights reserved</span>
        </div>
        <div class="col-12 col-md-4 justify-center text-xs">
          <div class="d-flex align-items-center">
            <i class="bi bi-geo-alt-fill me-2 text-xl"></i>
            <div>
              <div class="d-flex align-middle">
                <span class="text-sm font-semibold"
                  >DIFC (Dubai International Financial Centre)</span
                >
              </div>
              <div class="d-flex align-middle">
                <span class="text-xs text-justify"
                  >West Wing, Dubai, UAE. DIFC is a leading financial hub,
                  offering a robust regulatory framework and infrastructure for
                  financial services companies, including Forex brokers.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 flex justify-center md:justify-end">
          <span class="text-xs">Medatiq @2024</span>
        </div>
      </div>
    </div>
    <el-tooltip content="Back to top">
      <el-backtop :right="15" :bottom="100" />
    </el-tooltip>
    <!-- <el-tooltip content="Contact Us">
      <div class="whatsapp" @click="whatsappContact()">
        <img src="../../assets/img/whatsapp.svg" alt="whatsapp" />
      </div>
    </el-tooltip> -->
    <div>
      <a
        href="https://wa.me/971558098395"
        target="_blank"
        class="btn-whatsapp-pulse"
      >
        <i class="bi bi-whatsapp text-white" aria-hidden="true"></i>
      </a>
    </div>
    <!-- <el-tooltip content="Contact Us">
      <n-float-button :right="15" :bottom="15" shape="circle"  class="whatsapp p-0" width="100" type="primary" @click="whatsappContact()">
        <n-icon>
          <i class="bi bi-whatsapp"></i>
        </n-icon>
      </n-float-button>
    </el-tooltip> -->
  </div>
</template>
<script>
import { NButton, NCard, NSpace, NTag } from "naive-ui";
import colors from "@/Utility/colors";

export default {
  name: "HomePage",
  computed: {
    colors() {
      return colors;
    },
  },
  components: { NSpace, NCard, NTag, NButton },
  data() {
    return {
      tableData: [
        {
          icon: "https://d33vw3iu5hs0zi.cloudfront.net/media/XAUUSD_64f4c10126.png",
          title: "XAUUSD",
          subTitle: "Gold vs US Dollar",
          leverage: "Customizable",
          pips: 12.5,
          swapFree: "Available",
          link: {
            text: "Metal",
            link: "",
          },
        },
        {
          icon: "https://d33vw3iu5hs0zi.cloudfront.net/media/USOIL_8bb32a7dca.png",
          title: "USOIL",
          subTitle: "Crude Oil",
          leverage: "1:200",
          pips: 1.2,
          swapFree: "Swap applied",
          link: {
            text: "Energies",
            link: "",
          },
        },
        {
          icon: "https://d33vw3iu5hs0zi.cloudfront.net/media/EURUSD_e36fe5b220.png",
          title: "EURUSD",
          subTitle: "Euro vs US Dollar",
          leverage: "Customizable",
          pips: 0.6,
          swapFree: "Available",
          link: {
            text: "Currencies",
            link: "",
          },
        },
        {
          icon: "https://d33vw3iu5hs0zi.cloudfront.net/media/US_30_1c61cd15fd.png",
          title: "US30",
          subTitle: "US Wall Street 30 Index",
          leverage: "1:400",
          pips: 4.9,
          swapFree: "Available",
          link: {
            text: "Indices",
            link: "",
          },
        },
        {
          icon: "https://d33vw3iu5hs0zi.cloudfront.net/media/APPL_07775c0743.png",
          title: "APPL",
          subTitle: "Apple Inc.",
          leverage: "1:200",
          pips: 0.6,
          swapFree: "Swap applied",
          link: {
            text: "Stocks",
            link: "",
          },
        },
      ],
    };
  },
  methods: {
    whatsappContact() {
      window.open("https://wa.me/923139797999", "_blank");
    },
  },
};
</script>

<style scoped>
.table-cell {
  background: transparent !important;
}

.tbl-header {
  background-color: transparent !important;
}

.el-table tr {
  background-color: #8b1818 !important;
}

.el-table--border .el-table__inner-wrapper:after,
.el-table--border:after,
.el-table--border:before,
.el-table__inner-wrapper:before {
  background-color: rgba(10, 83, 190, 0.61) !important;
  content: "";
  position: absolute;
  z-index: calc(var(--el-table-index) + 2);
}
.whatsapp {
  position: fixed;
  bottom: 15px;
  right: 10px;
  cursor: pointer;
}
.whatsapp img {
  width: 40px;
}
.btn-whatsapp-pulse {
  background: #1ad03f !important;
  color: white !important;
  position: fixed;
  bottom: 15px !important;
  right: 25px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 30px;
  text-decoration: none !important;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 15;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #1ad03f;
  }
  80% {
    box-shadow: 0 0 0 20px rgba(37, 211, 102, 0);
  }
}
</style>
